<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model='filters.keyWord' @input='() => filters.keyWord = filters.keyWord.replace(/\s/g,"")' placeholder='输入培训机构/课程名称' clearable @clear='getLearningEnrollSummaryListPage' @keyup.enter.native='getLearningEnrollSummaryListPage'></el-input>
                    </el-form-item>
                    <el-form-item label='城市：'>
                        <el-select v-model="filters.cityID" placeholder="请选择" v-loading='loading1' clearable @change='handleCityChange' @clear='clearCity'>
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='区/县：'>
                        <el-select v-model="filters.regionID" placeholder="请选择" v-loading='loading2' clearable @change='handleAreaChange' @clear='clearArea'>
                            <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        v-loading='listLoading'
        :data="tableData"
        :row-style='{height:"38px"}'
        show-summary
        highlight-current-row
        style="width: 100%">
            <el-table-column type="index" width="55" label='序号' align='center'></el-table-column>
            <el-table-column prop='AgencyName' width="" label='培训机构名称' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='TeachingMaterialName' width="" label='课程名称' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='StudentCount' width="" label='报名数量' align='center'></el-table-column>
            <el-table-column prop='PayCount' width="" label='已缴费数量' align='center'></el-table-column>
        </el-table> 
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import {getAreaDictionaryList,getLearningEnrollSummaryListPage} from '@/api/api'
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList: [],
            filters:{
                keyWord:"",
                cityID:'',
                regionID:''
            },
            options:[], // 城市
            options1:[], // 区县
            loading1:false,
            loading2:false,
            listLoading:false, // 列表加载
            tableData:[],
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            }
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 得到城市、区县数据
        getPlaceData(params){                      
            params.parentId = 1919
            this.getcityData(params)      
        },
        getcityData(params){
            getAreaDictionaryList(params).then(res => {
                this.loading1 = false
                if(res.data.Success){
                    var data = res.data.Response
                    this.options = data.map(item => ({
                        label : item.Name,
                        value : item.ID
                    })).reverse()
                }else{return}
            }).catch(() => {this.loading1 = false})
        },
        getAreaData(params){   
            this.loading2 = true        
            getAreaDictionaryList(params).then(res => {
                this.loading2 = false
                if(res.data.Success){
                    var data = res.data.Response
                    this.options1 = data.map(item => ({
                        label : item.Name,
                        value : item.ID
                    })).reverse()
                }else{return}
            }).catch(() => {this.loading2 = false})
        },
        handleCityChange(value){ 
            if(value){
                this.filters.regionID = ''
                this.getAreaData({level:2,parentId:value})
                this.getLearningEnrollSummaryListPage()
            }else{
                this.filters.cityID = ''
                this.filters.regionID = ''
                this.options1 = [] 
            }
        },
        handleAreaChange(value){
            if(value){
                this.getLearningEnrollSummaryListPage()
            }                      
        },
        clearCity(){ 
            this.handleCityChange()
            this.loading2 = false   
            this.getLearningEnrollSummaryListPage()      
        },
        clearArea(){
            this.filters.regionID = ''
            this.handleAreaChange()
            this.getLearningEnrollSummaryListPage()   
        },
        
        // 查询
        getLearningEnrollSummaryListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 获取列表数据
        getData(){
            var params = {
                provinceID:1919,
                cityID:this.filters.cityID,
                regionID:this.filters.regionID,
                keyWorks:this.filters.keyWord,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            this.listLoading = true
            getLearningEnrollSummaryListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                     this.$message.error(res.data.Message)
                }
            }).catch(() => {
                this.listLoading = false
                this.$message.error(res.data.Message)
            })
        },
        // 分页
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        },
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
        }
        next()
    },
    created(){
        var params = {level:1}
        this.loading1 = true
        this.getPlaceData(params)
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style scoped>
    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 400px;
    }
</style>